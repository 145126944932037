import { useState, useEffect } from 'react';

export default function makeUseFetcher<T>() {
    const useCached = makeUseCache<T>();
    return function useFetcher(makesPromise: () => Promise<T>, dependencyList?: unknown[]): [T | undefined, boolean] {
        const [content, setContent] = useState<T>();
        const [loading, setLoading] = useState<boolean>(false);
        let done = false;
     
        useEffect(() => {
            if (done) {
                return;
            }
            setLoading(true);
            useCached(makesPromise, dependencyList).then((resp) => { 
                if (!done) {
                    setContent(resp);
                    setLoading(false);
                }
            });
            return () => {
                done = true;
            }
        }, dependencyList);
        return [content, loading];
    }
}

class JankyCache<T> {
    mainKey: string;
    lru: string[];
    maxSize: number;
    constructor(mainKey: string, maxSize = 100) {
        this.mainKey = mainKey;
        this.maxSize = maxSize;
        this.lru = [];
        if (!localStorage[mainKey]) {
            localStorage[mainKey] = JSON.stringify({});
        }
    }
    parse() {
        return JSON.parse(localStorage[this.mainKey]) || {} as Record<string, T>;
    }
    read(subKey: string) {
        const parsed = this.parse();
        if (parsed) {
            if (this.lru.length >= this.maxSize) {
                this.lru.shift();
                this.lru.push(subKey);
            }
            return parsed[subKey];
        }
        return undefined;
    }
    write(subKey: string, record: T) {
        const parsed = this.parse();
        if (this.lru.length >= this.maxSize) {
            const oldest = this.lru.shift();
            this.lru.push(subKey);
            if (oldest !== undefined) {
                delete parsed[oldest];
            }
        }
        parsed[subKey] = record;
        localStorage[this.mainKey] = JSON.stringify(parsed);
        return record;
    }
}

function makeUseCache<T>(cacheKey = 'useCached', maxSize = 100) {
    let cache = new JankyCache<T>(cacheKey, maxSize);
    return async function useCached(makesPromise: () => Promise<T>, dependencyList?: unknown[]): Promise<T> {
        const key = JSON.stringify(dependencyList);
        const cachedVal = cache.read(key);
        if (cachedVal){
            return cachedVal;
        } else {
            const val = await makesPromise();
            return cache.write(key, val);
        }
    }
}

import { useState } from 'react';
import { useFetcher, getAboutHogrottenContent } from './data';
import ideas from './storyTypes';
import Loader from './Loader';

const initIdeaIdx = Math.floor(Math.random() * ideas.length);

function getNextIdeaIdx(currIdx : number) {
    if (currIdx + 1 < ideas.length) {
        return currIdx + 1;
    }
    return 0;
}

export default function About() {

    const [nextIdeaIdx, setNextIdeaIdx] = useState(getNextIdeaIdx(initIdeaIdx));
    const [currIdea, setCurrIdea] = useState(ideas[initIdeaIdx]);
    const [nextIdea, setNextIdea] = useState(ideas[nextIdeaIdx]);
    const [response, loading] = useFetcher(async () => getAboutHogrottenContent(currIdea), [currIdea]);

    return <div>
        <p>About Hogrotten: in the style of {currIdea}</p>
        <pre>{ loading ? <Loader/> : response?.content }</pre>
        Style: 
        <textarea rows={ 4 } value={ nextIdea } onChange={ (e) => setNextIdea(e.target.value) }></textarea>
        <button onClick={() => {
            setCurrIdea(nextIdea);
         } 
        }>Write it like this!</button>
        <button onClick={ () => {
            const advancedOneIdx = getNextIdeaIdx(nextIdeaIdx);
            setNextIdeaIdx(advancedOneIdx);
            setNextIdea(ideas[advancedOneIdx]);
        }}>No, something different!</button>
    </div>;
}

const showIdeas = [
    "Pencil & Paint: Nicole has to redesign Andrew's office at the school. The kids secretly give their own inputs, leading to some humorous and quirky features.",
    "School Dance Dilemma: Andrew is in charge of the school dance. His own children give him \"advice\" to make it cool, leading to some unexpected results.",
    "Principal’s Day Off: Andrew takes a day off to spend time with Nicole but keeps getting called or involved in school matters, much to Nicole's frustration.",
    "Blueprint Mix-Up: Nicole's office plans get mixed up with a student's prank plans. Andrew unknowingly uses them for a school project.",
    "Parent-Teacher Night: One of the kids is in trouble, and Andrew has to be both the principal and the parent at a parent-teacher conference.",
    "Open House Overload: Nicole hosts an open house for her latest project. At the same time, Andrew mistakenly thinks it's a surprise party for him.",
    "Assistant for a Day: The youngest child becomes “Principal for a Day” and starts enacting ridiculous school rules.",
    "A Desk Too Far: Nicole designs a new desk for Andrew. It's either too big, too futuristic, or just downright impractical, but Andrew doesn’t have the heart to tell her.",
    "Mascot Madness: Andrew tries to introduce a new school mascot but the costume gets mixed up with something Nicole ordered for a client’s themed room.",
    "The Undercover Student: To understand the kids better, Andrew goes undercover as a student. His own kids and the teachers are in on it, leading to comedic situations.",
    "Presentation Panic: Nicole has a major presentation but the kids accidentally mess up her design model. Andrew and the kids scramble to fix it in time.",
    "Field Trip Fiasco: Andrew organizes a field trip to Nicole’s latest architectural marvel. The kids cause chaos during the tour.",
    "Sleepover Slip: Nicole thinks she's agreed to one of the kids having a single friend over, but it turns into an impromptu sleepover party with half the class.",
    "Lunchbox Love Notes: Andrew starts leaving goofy notes in the kids' lunchboxes. They become a hit, and soon every kid wants one.",
    "Career Day Confusion: Both Andrew and Nicole are slated to present on career day, leading to hilarious one-upmanship and unexpected revelations about their professions.",
    "Locked In: Andrew gets locked in his office overnight. Nicole and the kids plan a hilarious rescue mission.",
    "Renovation Rebellion: The kids decide to \"help\" by redecorating their rooms without telling Nicole. The results are... unique.",
    "Mural Mayhem: Nicole is commissioned to create a mural for the school, but the kids and their friends give it their own colorful \"touch-ups\".",
    "Secret Santa Swap: At the school’s secret Santa event, gifts get mixed up, leading to some hilarious mismatches and exchanges.",
    "Principal’s Pet: Andrew brings home the school’s pet hamster for the weekend, but it goes missing, turning the family home into a chaotic search ground.",
    "Birthday Blunder: Both Andrew and Nicole plan surprise parties for each other on the same day, leading to a double surprise and twice the chaos.",
    "Homework Hacks: Andrew discovers a cheat sheet and has to find the culprits. Little does he know, one of his own kids is involved.",
    "Office Olympics: To blow off steam, Nicole and her colleagues start \"office Olympics\". Andrew gets wind of it and wants in.",
    "Drama Club Chaos: Andrew volunteers to direct the school's drama club, but his theatrical ideas are hilariously outlandish.",
    "Principal's Podcast: Andrew starts a podcast about life as a principal. The kids secretly listen in and offer him unsolicited advice on content."
]
export default showIdeas;
import { useState } from 'react';
import { useFetcher, getAndrewNicoleContent } from './data';
import ideas from './AndrewNicoleShow';
import Loader from './Loader';

const initIdeaIdx = Math.floor(Math.random() * ideas.length);

function getNextIdeaIdx(currIdx : number) {
    if (currIdx + 1 < ideas.length) {
        return currIdx + 1;
    }
    return 0;
}

export default function AndrewNicole() {

    const [nextIdeaIdx, setNextIdeaIdx] = useState(getNextIdeaIdx(initIdeaIdx));
    const [currIdea, setCurrIdea] = useState(ideas[initIdeaIdx]);
    const [nextIdea, setNextIdea] = useState(ideas[nextIdeaIdx]);
    const [response, loading] = useFetcher(async () => getAndrewNicoleContent(currIdea), [currIdea]);

    return <div>
        <p>{ currIdea }</p> 
        <pre>{ loading ? <Loader/> : response?.content }</pre>
        Next Idea: 
        <textarea rows={ 4 } value={ nextIdea } onChange={ (e) => setNextIdea(e.target.value) }></textarea>
        <button onClick={() => {
            setCurrIdea(nextIdea);
         } 
        }>Rewrite with this idea</button>
        <button onClick={ () => {
            const advancedOneIdx = getNextIdeaIdx(nextIdeaIdx);
            setNextIdeaIdx(advancedOneIdx);
            setNextIdea(ideas[advancedOneIdx]);
        }}>Give me a different idea</button>
    </div>;
}

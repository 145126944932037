import { createRoot } from 'react-dom/client';
import { Tabs, TabList, TabPanel, Tab } from 'react-aria-components';
import AndrewNicole from './AndrewNicole';
import AuGratin from './Augratin';
import About from './About';
import TrashPandas from './Trashpandas';

const root = createRoot(document.getElementById('root') as Element);

root.render(<Main/>);

function Main() {
    return <Tabs>
        <TabList aria-label="Hogrotten menu">
            <Tab id="aboutHogrotten">About Hogrotten</Tab>
            <Tab id="auGratin">Au Gratin</Tab>
            <Tab id='trashPanda'>Trash Pandas</Tab>
            <Tab id='andrewNicole'>Andrew and Nicole</Tab>
        </TabList>
        <TabPanel id="aboutHogrotten">
            <About />
        </TabPanel>
        <TabPanel id="auGratin">
            <AuGratin />
        </TabPanel>
        <TabPanel id="trashPanda">
            <TrashPandas />
        </TabPanel>
        <TabPanel id="andrewNicole">
            <AndrewNicole />
        </TabPanel>
    </Tabs>

}
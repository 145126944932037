import makeUseFetcher from "./useFetcher";

type LLMContent = { content: string };
const useFetcher = makeUseFetcher<LLMContent>();


const aiUrl = 'https://hogrotten.com/ai/';

async function getAndrewNicoleContent(currIdea: string){
    const resp = await fetch(`${aiUrl}?kind=andrewNicole&story=${encodeURIComponent(currIdea)}`)
    return resp.json() as Promise<LLMContent>;
}
async function getAuGratinContent(ingredient: string) {
    const resp = await fetch(`${aiUrl}?kind=auGratin&ingredient=${encodeURIComponent(ingredient)}`)
    return resp.json() as Promise<LLMContent>;
}
async function getAboutHogrottenContent(style: string) {
    const resp = await fetch(`${aiUrl}?kind=aboutHogrotten&style=${encodeURIComponent(style)}`)
    return resp.json() as Promise<LLMContent>;
}

export {
    getAndrewNicoleContent,
    getAuGratinContent,
    getAboutHogrottenContent,
    useFetcher
}
export default [
    "A somber Hemingway short story",
    "A silly Samuel L. Jackson monologue",
    "A melancholic Dr. Seuss rhyme",
    "A sarcastic J.R.R. Tolkien lecture",
    "A giddy Edgar Allan Poe poem",
    "A flirty George Orwell essay",
    "An over-caffeinated Jane Austen soliloquy",
    "A gothic Roald Dahl short story",
    "A melodramatic Agatha Christie monologue",
    "A blasé F. Scott Fitzgerald essay",
    "A punk rock Shakespeare soliloquy",
    "A hyperactive Charles Dickens speech",
    "A moody J.K. Rowling essay",
    "A lovesick Ernest Hemingway short story",
    "An existential Mary Shelley monologue",
    "A breezy Stephen King essay",
    "A paranoid Mark Twain soliloquy",
    "A bewildered Leo Tolstoy short story",
    "A rebellious Maya Angelou monologue",
    "A zen Oscar Wilde speech",
    "A sassy Margaret Atwood essay",
    "A befuddled Isaac Newton monologue"
];
const ingredients = [
    {
        "name": "Chocolate",
        "description": "Grated dark chocolate between layers for bittersweet depth."
    },
    {
        "name": "Pickled Ginger",
        "description": "That pink stuff from sushi restaurants."
    },
    {
        "name": "Seaweed",
        "description": "Crushed nori sheets for an umami kick."
    },
    {
        "name": "Liquorice",
        "description": "Either powdered or as a sauce, for anise-like sweetness."
    },
    {
        "name": "Miso and Chocolate Mix",
        "description": "A fusion of sweet, salty, and umami."
    },
    {
        "name": "Tamarind Paste",
        "description": "Sour and tangy."
    },
    {
        "name": "Bee Pollen",
        "description": "Nutty and slightly sweet."
    },
    {
        "name": "Candied Bacon",
        "description": "Sweet, salty, smoky, crunchy."
    },
    {
        "name": "Blue Cheese",
        "description": "Moldy, tangy surprise."
    },
    {
        "name": "Coffee Grounds",
        "description": "Just a sprinkle for a roasted bitterness."
    },
    {
        "name": "Dried Shrimp",
        "description": "Ground up for a salty, seafood touch."
    },
    {
        "name": "Peanut Butter and Sriracha Mix",
        "description": "Spicy and nutty."
    },
    {
        "name": "Edible Flowers",
        "description": "For a burst of color and mild floral notes."
    },
    {
        "name": "Sardines",
        "description": "Finely chopped for a briny surprise."
    },
    {
        "name": "Chia Seeds",
        "description": "When soaked, they become gelatinous and could add an interesting texture."
    },
    {
        "name": "Pickled Jalapeños",
        "description": "Tangy and spicy."
    },
    {
        "name": "Earl Grey Tea",
        "description": "Infuse the cream with this bergamot-flavored tea."
    },
    {
        "name": "Popcorn with Nutritional Yeast",
        "description": "Cheesy, salty, crunchy topping."
    },
    {
        "name": "Coconut Milk with Curry",
        "description": "Replacing regular milk for a tropical and spicy twist."
    },
    {
        "name": "Anchovy-Stuffed Olives",
        "description": "Chopped up for a mix of briny and salty."
    },
    {
        "name": "Crushed Wasabi Peas",
        "description": "Spicy, crunchy surprise."
    },
    {
        "name": "Fermented Black Beans",
        "description": "Salty and slightly bitter."
    },
    {
        "name": "Bubble Gum Syrup",
        "description": "Drizzled on top or infused in the cream."
    },
    {
        "name": "Crushed Pretzels",
        "description": "Salty and crunchy topping."
    },
    {
        "name": "Pickled Eggs",
        "description": "Sliced and layered in for a tangy protein hit."
    }, 
    { 
        "name": "Sawdust",
        "description": "Tastes like bark"
    }
]
export default ingredients;
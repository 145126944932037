import { useState } from 'react';
import { useFetcher, getAndrewNicoleContent, getAuGratinContent } from './data';
import ideas from './ingredients';
import Loader from './Loader';

const gratin = new URL('gratin.jpg', import.meta.url) as unknown as string;

const initIdeaIdx = Math.floor(Math.random() * ideas.length);

function getNextIdeaIdx(currIdx : number) {
    if (currIdx + 1 < ideas.length) {
        return currIdx + 1;
    }
    return 0;
}

export default function AuGratin() {

    const [nextIdeaIdx, setNextIdeaIdx] = useState(getNextIdeaIdx(initIdeaIdx));
    const [currIdea, setCurrIdea] = useState(ideas[initIdeaIdx].name);
    const [nextIdea, setNextIdea] = useState(ideas[nextIdeaIdx].name);
    const [response, loading] = useFetcher(async () => getAuGratinContent(currIdea), [currIdea]);

    return <div>
        <p>Au Gratin with {currIdea}</p>
        <img width="400px" src={gratin}/>
        <pre>{ loading ? <Loader/> : response?.content }</pre>
        Choose Ingredient: 
        <textarea rows={ 4 } value={ nextIdea } onChange={ (e) => setNextIdea(e.target.value) }></textarea>
        <button onClick={() => {
            setCurrIdea(nextIdea);
         } 
        }>Rewrite with this ingredient</button>
        <button onClick={ () => {
            const advancedOneIdx = getNextIdeaIdx(nextIdeaIdx);
            setNextIdeaIdx(advancedOneIdx);
            setNextIdea(ideas[advancedOneIdx].name);
        }}>Give me a different ingredient</button>
    </div>;
}
